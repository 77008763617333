import React from 'react'
import Header from '../compunet/Header'
import Footer from '../compunet/Footer'
import { Link } from 'react-router-dom'
const Parenthood = () => {
    return (
        <>
            <body className="moto-background moto-website_live">

                <div className="page">
                    <Header />
                    {/* <header
id="section-header"
className="header moto-section"
data-widget="section"
data-container="section"
>
<div
className="moto-widget moto-widget-row row-fixed moto-justify-content_center moto-bg-color1_3 moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto"
data-grid-type="sm"
data-widget="row"
data-visible-on="-"
data-spacing="sasa"
style={{}}
data-bg-position="left top"
data-draggable-disabled=""
>
<div className="container-fluid">
<div className="row" data-container="container">
<div
className="moto-widget moto-widget-row__column moto-cell col-sm-8 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
style={{}}
data-widget="row.column"
data-container="container"
data-spacing="aaaa"
data-bg-position="left top"
>
<div
className="moto-widget moto-widget-row moto-justify-content_center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
data-grid-type="sm"
data-widget="row"
data-visible-on="-"
data-spacing="aaaa"
style={{}}
data-bg-position="left top"
data-draggable-disabled=""
>
<div className="container-fluid">
<div className="row" data-container="container">
<div
className="moto-widget moto-widget-row__column moto-cell col-sm-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
style={{}}
data-widget="row.column"
data-container="container"
data-spacing="aaaa"
data-bg-position="left top"
>
<div
className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
data-widget="text"
data-preset="default"
data-spacing="aaaa"
data-visible-on="-"
data-animation=""
>
<div className="moto-widget-text-content moto-widget-text-editable">
  <p className="moto-text_system_2">
    <span className="fa"></span>&nbsp;&nbsp;7087
    Richmond hwy, Alexandria, VA
  </p>
</div>
</div>
</div>
<div
className="moto-widget moto-widget-row__column moto-cell col-sm-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
style={{}}
data-widget="row.column"
data-container="container"
data-spacing="aaaa"
data-bg-position="left top"
>
<div
className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
data-widget="text"
data-preset="default"
data-spacing="aaaa"
data-visible-on="-"
data-animation=""
data-draggable-disabled=""
>
<div
  className="moto-widget-text-content moto-widget-text-editable"
  aria-multiline="true"
>
  <p className="moto-text_system_2">
    <span className="fa"></span>&nbsp;&nbsp;
    <a
      href="tel:1234567890"
      data-action="call"
      className="moto-link"
    >
      123-456-7890
    </a>
  </p>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
<div
className="moto-widget moto-widget-row__column moto-cell col-sm-4 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
style={{}}
data-widget="row.column"
data-container="container"
data-spacing="aaaa"
data-bg-position="left top"
>
<div
id="wid_1595839680_hbtt21zug"
data-widget-id="wid_1595839680_hbtt21zug"
className="moto-widget moto-widget-social-links-extended moto-preset-default moto-align-right moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
data-widget="social_links_extended"
data-preset="default"
>
<ul className="moto-widget-social-links-extended__list">
<li className="moto-widget-social-links-extended__item moto-widget-social-links-extended__item-1">
<a
href="#"
className="moto-widget-social-links-extended__link"
target="_self"
>
<span className="moto-widget-social-links-extended__icon fa">

</span>
</a>
</li>
<li className="moto-widget-social-links-extended__item moto-widget-social-links-extended__item-2">
<a
href="#"
className="moto-widget-social-links-extended__link"
target="_self"
>
<span className="moto-widget-social-links-extended__icon fa">

</span>
</a>
</li>
<li className="moto-widget-social-links-extended__item moto-widget-social-links-extended__item-3">
<a
href="#"
className="moto-widget-social-links-extended__link"
target="_self"
>
<span className="moto-widget-social-links-extended__icon fa">

</span>
</a>
</li>
<li className="moto-widget-social-links-extended__item moto-widget-social-links-extended__item-4">
<a
href="#"
className="moto-widget-social-links-extended__link"
target="_self"
>
<span className="moto-widget-social-links-extended__icon fa">

</span>
</a>
</li>
</ul>
<style
type="text/css"
dangerouslySetInnerHTML={{
__html:
"\n                                                        "
}}
/>
</div>
</div>
</div>
</div>
</div>
<div
className="moto-widget moto-widget-container undefined moto-container_header_5f1e96be1"
data-visible-on=""
data-widget="container"
data-container="container"
data-css-name="moto-container_header_5f1e96be1"
data-bg-position="left top"
data-moto-sticky="{}"
>
<div
className="moto-widget moto-widget-row row-fixed moto-justify-content_center moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto"
data-grid-type="sm"
data-widget="row"
data-visible-on="-"
data-spacing="sasa"
style={{}}
data-bg-position="left top"
>
<div className="container-fluid">
<div className="row" data-container="container">
<div
className="moto-widget moto-widget-row__column moto-cell col-sm-3 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
style={{}}
data-widget="row.column"
data-container="container"
data-spacing="aaaa"
data-bg-position="left top"
>
<div
data-widget-id="wid_1595840209_7o89ubw42"
className="moto-widget moto-widget-image moto-widget_with-deferred-content moto-preset-default moto-align-center_mobile-h moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto  "
data-widget="image"
>
<a
href="../index.html"
data-action="home_page"
className="moto-widget-image-link moto-link"
>
<img
data-src="/mt-demo/109600/109604/mt-content/uploads/2020/07/mt-2068_header_logo.png"
src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0  '%3E%3C/svg%3E"
className="moto-widget-image-picture moto-widget-deferred-content lazyload"
data-id={145}
title=""
alt=""
/>
</a>
</div>
</div>
<div
className="moto-widget moto-widget-row__column moto-cell col-sm-9 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
style={{}}
data-widget="row.column"
data-container="container"
data-spacing="aaaa"
data-bg-position="left top"
>
<div
data-widget-id="wid_1595840247_yzpb4jpes"
className="moto-widget moto-widget-menu moto-preset-default moto-align-center moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto"
data-preset="default"
data-widget="menu"
>
<a href="#" className="moto-widget-menu-toggle-btn">
<i className="moto-widget-menu-toggle-btn-icon fa fa-bars" />
</a>
<ul className="moto-widget-menu-list moto-widget-menu-list_horizontal">
<li className="moto-widget-menu-item">
<a
href="index.html"
data-action="page"
className="moto-widget-menu-link moto-widget-menu-link-level-1 moto-widget-menu-link-active moto-link"
>
ABOUT US
</a>
</li>
<li className="moto-widget-menu-item">
<a
href="../our-team/index.html"
data-action="page"
className="moto-widget-menu-link moto-widget-menu-link-level-1 moto-link"
>
DOCTORS
</a>
</li>
<li className="moto-widget-menu-item">
<a
href="../services/index.html"
data-action="page"
className="moto-widget-menu-link moto-widget-menu-link-level-1 moto-link"
>
SERVICES
</a>
</li>
<li className="moto-widget-menu-item">
<a
href="../contacts/index.html"
data-action="page"
className="moto-widget-menu-link moto-widget-menu-link-level-1 moto-link"
>
CONTACTS
</a>
</li>
<li className="moto-widget-menu-item moto-widget-menu-item-has-submenu">
<a
href="#"
data-action="url"
className="moto-widget-menu-link moto-widget-menu-link-level-1 moto-widget-menu-link-submenu moto-link"
>
MORE
<span className="fa moto-widget-menu-link-arrow" />
</a>
<ul className="moto-widget-menu-sublist">
<li className="moto-widget-menu-item">
  <a
    href="../gallery/index.html"
    data-action="page"
    className="moto-widget-menu-link moto-widget-menu-link-level-2 moto-link"
  >
    GALLERY
  </a>
</li>
<li className="moto-widget-menu-item">
  <a
    href="../departments/index.html"
    data-action="page"
    className="moto-widget-menu-link moto-widget-menu-link-level-2 moto-link"
  >
    DEPARTMENTS
  </a>
</li>
<li className="moto-widget-menu-item">
  <a
    href="../blog/index.html"
    data-action="blog.index"
    className="moto-widget-menu-link moto-widget-menu-link-level-2 moto-link"
  >
    BLOG
  </a>
</li>
</ul>
</li>{" "}
</ul>
</div>
</div>
</div>
</div>
</div>
</div>
<div
className="moto-widget moto-widget-row row-fixed moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto"
data-grid-type="sm"
data-widget="row"
data-visible-on="-"
data-spacing="mama"
style={{}}
data-bg-position="left top"
>
<div className="container-fluid">
<div className="row" data-container="container">
<div
className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
style={{}}
data-widget="row.column"
data-container="container"
data-spacing="aaaa"
data-bg-position="left top"
>
<div
id="wid_1595852959_fff0o9hk4"
className="moto-widget moto-widget-breadcrumbs moto-align-left moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto   moto-preset-default"
data-widget="breadcrumbs"
data-preset="default"
>
<div className="moto-widget-breadcrumbs__wrapper">
<ul className="moto-widget-breadcrumbs__list">
<li className="moto-widget-breadcrumbs__item">
<span className="moto-widget-breadcrumbs__item-content moto-widget-breadcrumbs__item-content_current">
<span className="moto-widget-breadcrumbs__item-text">
  ABOUT US
</span>
</span>
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
</div>
</header> */}
                    <section
                        id="section-content"
                        className="content page-12 moto-section"
                        data-widget="section"
                        data-container="section"
                    >
                        <div
                            className="moto-widget moto-widget-row moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                            data-grid-type="sm"
                            data-widget="row"
                            data-visible-on="-"
                            data-spacing="aaaa"
                            style={{}}
                            data-bg-position="left top"
                            data-draggable-disabled=""
                        >
                            <div className="">
                                <div
                                    id="wid_1595841006_s9ff8o2hk__content"
                                    className="moto-widget__content"
                                >
                                    <div
                                        id="wid_1595841023_rrwvo0wxl"
                                        data-parent-id="wid_1595841006_s9ff8o2hk"
                                        className="moto-widget moto-widget-content_slider__item moto-widget-row moto-widget_with-content-editor  row-fixed moto-disabling_row-fixed moto-entertainment__playing_disabled moto-before-in-viewport_content-invisible"
                                        style={{
                                            backgroundPosition: "top",
                                            backgroundImage:
                                                "url(mt-demo/109600/109604/mt-content/uploads/2020/07/mt-2068_home_bg03.jpg)",
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat"
                                        }}
                                        data-widget="content_slider.item"
                                        data-preset=""
                                    >
                                        <div className="moto-widget__content-wrapper container-fluid">
                                            <div className="moto-widget__content row">
                                                <div
                                                    id="wid_1595841023_rrwvo0wxl__content"
                                                    className="moto-cell col-sm-12 moto-entertainment__animation_disabled moto-entertainment__animation_ignore-visibility"
                                                >
                                                    <div
                                                        data-widget-id="wid_1595841057_t4stgm9px"
                                                        className="moto-widget moto-widget-row moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto  "
                                                        data-grid-type="sm"
                                                        data-widget="row"
                                                        data-visible-on="+desktop,tablet,mobile-h,mobile-v"
                                                        data-spacing="lala"
                                                        style={{ backgroundPosition: "left top" }}
                                                    >
                                                        <div className="container-fluid">
                                                            <div className="row" data-container="container">
                                                                <div
                                                                    data-widget-id="wid_1595841057_89be3r1sr"
                                                                    className="moto-widget moto-widget-row__column moto-cell  col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                                                    style={{ backgroundPosition: "left top" }}
                                                                    data-widget="row.column"
                                                                    data-container="container"
                                                                    data-spacing="aaaa"
                                                                >
                                                                    <div
                                                                        data-widget-id="wid_1595841837_ubu65qgf0"
                                                                        className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto moto-visible-on_tablet_hidden moto-visible-on_mobile-h_hidden moto-visible-on_mobile-v_hidden"
                                                                        data-widget="spacer"
                                                                        data-preset="default"
                                                                        data-spacing="laaa"
                                                                        data-visible-on="+desktop"
                                                                    >
                                                                        <div
                                                                            className="moto-widget-spacer-block"
                                                                            style={{ height: 0 }}
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        data-widget-id="wid_1595841066_6joo2ll8u"
                                                                        className="moto-widget moto-widget-text moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
                                                                        data-widget="text"
                                                                        data-preset="default"
                                                                        data-spacing="aaaa"
                                                                        data-visible-on="+desktop,tablet,mobile-h,mobile-v"
                                                                        data-animation=""
                                                                    >
                                                                        <div className="moto-widget-text-content moto-widget-text-editable">
                                                                            {/* <p className="moto-text_system_5">
                WE HAVE A SET OF
              </p> */}
                                                                            {/* <h1 className="moto-text_system_3">
                SERVICES TO HELP
              </h1> */}
                                                                            <p className="moto-text_system_4">
                                                                                STEPS TO PARENTHOOD
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        data-widget-id="wid_1595841737_olwfxtvqo"
                                                                        className="moto-widget moto-widget-row moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto  "
                                                                        data-grid-type="sm"
                                                                        data-widget="row"
                                                                        data-visible-on="+desktop,tablet,mobile-h,mobile-v"
                                                                        data-spacing="mama"
                                                                        style={{ backgroundPosition: "left top" }}
                                                                    >
                                                                        <div className="container-fluid">
                                                                            <div className="row" data-container="container">
                                                                                <div
                                                                                    data-widget-id="wid_1595841737_64k6q85ws"
                                                                                    className="moto-widget moto-widget-row__column moto-cell  col-sm-7 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                                                                    style={{ backgroundPosition: "left top" }}
                                                                                    data-widget="row.column"
                                                                                    data-container="container"
                                                                                    data-spacing="aaaa"
                                                                                >
                                                                                    <div
                                                                                        data-widget-id="wid_1595841752_3xt78tb1c"
                                                                                        className="moto-widget moto-widget-text moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
                                                                                        data-widget="text"
                                                                                        data-preset="default"
                                                                                        data-spacing="aaaa"
                                                                                        data-visible-on="+desktop,tablet,mobile-h,mobile-v"
                                                                                        data-animation=""
                                                                                    >
                                                                                        {/* <div className="moto-widget-text-content moto-widget-text-editable">
                      <p className="moto-text_211">
                        Starting with a consultation on what
                        choices you'll have as a future
                        parent, and all the way to group
                        support classes and medical exams,
                        we're here to help!
                      </p>
                    </div> */}
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div
                  data-widget-id="wid_1595841739_yync027fc"
                  className="moto-widget moto-widget-row__column moto-cell  col-sm-5 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                  style={{ backgroundPosition: "left top" }}
                  data-widget="row.column"
                  data-container="container"
                  data-spacing="aaaa"
                ></div> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div
            data-widget-id="wid_1595841278_2xqb0ppjw"
            className="moto-widget moto-widget-button moto-preset-default moto-preset-provider-default moto-align-left moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
            data-widget="button"
          >
            <a
              // href="#"
              data-action="popup"
              data-popup-id={8}
              className="moto-widget-button-link moto-size-small moto-link"
            >
              <span className="fa moto-widget-theme-icon" />
              <span className="moto-widget-button-divider" />
              <span className="moto-widget-button-label">
                Make an appointment&nbsp;
              </span>
            </a>
          </div> */}
                                                                    <div
                                                                        data-widget-id="wid_1595841846_f2kcxi7k6"
                                                                        className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto moto-visible-on_tablet_hidden moto-visible-on_mobile-h_hidden moto-visible-on_mobile-v_hidden"
                                                                        data-widget="spacer"
                                                                        data-preset="default"
                                                                        data-spacing="laaa"
                                                                        data-visible-on="+desktop"
                                                                    >
                                                                        <div
                                                                            className="moto-widget-spacer-block"
                                                                            style={{ height: 0 }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div
id="wid_1595841927_bdfvoucbl"
data-parent-id="wid_1595841006_s9ff8o2hk"
className="moto-widget moto-widget-content_slider__item moto-widget-row moto-widget_with-content-editor  row-fixed moto-disabling_row-fixed moto-entertainment__playing_disabled moto-before-in-viewport_content-invisible"
style={{
backgroundPosition: "top",
backgroundImage:
"url(mt-demo/109600/109604/mt-content/uploads/2020/07/mt-2068_home_slider02.jpg)",
backgroundSize: "cover",
backgroundRepeat: "no-repeat"
}}
data-widget="content_slider.item"
data-preset=""
>
<div className="moto-widget__content-wrapper container-fluid">
<div className="moto-widget__content row">
<div
id="wid_1595841927_bdfvoucbl__content"
className="moto-cell col-sm-12 moto-entertainment__animation_disabled moto-entertainment__animation_ignore-visibility"
>
<div
data-widget-id="wid_1595841927_7x5uw1q2y"
className="moto-widget moto-widget-row moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto  "
data-grid-type="sm"
data-widget="row"
data-visible-on="+desktop,tablet,mobile-h,mobile-v"
data-spacing="lala"
style={{ backgroundPosition: "left top" }}
>
<div className="container-fluid">
<div className="row" data-container="container">
  <div
    data-widget-id="wid_1595841927_zr67jwsfa"
    className="moto-widget moto-widget-row__column moto-cell  col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
    style={{ backgroundPosition: "left top" }}
    data-widget="row.column"
    data-container="container"
    data-spacing="aaaa"
  >
    <div
      data-widget-id="wid_1595841927_gny5i9ikv"
      className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto moto-visible-on_tablet_hidden moto-visible-on_mobile-h_hidden moto-visible-on_mobile-v_hidden"
      data-widget="spacer"
      data-preset="default"
      data-spacing="laaa"
      data-visible-on="+desktop"
    >
      <div
        className="moto-widget-spacer-block"
        style={{ height: 0 }}
      />
    </div>
    <div
      data-widget-id="wid_1595841927_vqn575vq6"
      className="moto-widget moto-widget-text moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
      data-widget="text"
      data-preset="default"
      data-spacing="aaaa"
      data-visible-on="+desktop,tablet,mobile-h,mobile-v"
      data-animation=""
    >
      <div className="moto-widget-text-content moto-widget-text-editable">
        <p className="moto-text_system_5">
          THIS WEEK ONLY!
        </p>
        <p className="moto-text_system_3">GET A FREE</p>
        <p className="moto-text_system_4">
          CONSULTATION
        </p>
      </div>
    </div>
    <div
      data-widget-id="wid_1595841927_2eqhunz6h"
      className="moto-widget moto-widget-row moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto  "
      data-grid-type="sm"
      data-widget="row"
      data-visible-on="+desktop,tablet,mobile-h,mobile-v"
      data-spacing="mama"
      style={{ backgroundPosition: "left top" }}
    >
      <div className="container-fluid">
        <div className="row" data-container="container">
          <div
            data-widget-id="wid_1595841927_jkrwtgbq7"
            className="moto-widget moto-widget-row__column moto-cell  col-sm-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
            style={{ backgroundPosition: "left top" }}
            data-widget="row.column"
            data-container="container"
            data-spacing="aaaa"
          >
            <div
              data-widget-id="wid_1595841927_pync8qvne"
              className="moto-widget moto-widget-text moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
              data-widget="text"
              data-preset="default"
              data-spacing="aaaa"
              data-visible-on="+desktop,tablet,mobile-h,mobile-v"
              data-animation=""
            >
              <div className="moto-widget-text-content moto-widget-text-editable">
                <p className="moto-text_211">
                  Only during this week we offer you a
                  free of charge consultation. The offer
                  can be applied to absolutely any type
                  of pregnancy issue you might have!
                </p>
              </div>
            </div>
          </div>
          <div
            data-widget-id="wid_1595841927_s8yk2533l"
            className="moto-widget moto-widget-row__column moto-cell  col-sm-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
            style={{ backgroundPosition: "left top" }}
            data-widget="row.column"
            data-container="container"
            data-spacing="aaaa"
          ></div>
        </div>
      </div>
    </div>
    <div
      data-widget-id="wid_1595841927_m39abk6yy"
      className="moto-widget moto-widget-button moto-preset-default moto-preset-provider-default moto-align-left moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
      data-widget="button"
    >
      <a
        href="#"
        data-action="popup"
        data-popup-id={8}
        className="moto-widget-button-link moto-size-small moto-link"
      >
        <span className="fa moto-widget-theme-icon" />
        <span className="moto-widget-button-divider" />
        <span className="moto-widget-button-label">
          Make an appointment&nbsp;
        </span>
      </a>
    </div>
    <div
      data-widget-id="wid_1595841927_5ua93h4yr"
      className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto moto-visible-on_tablet_hidden moto-visible-on_mobile-h_hidden moto-visible-on_mobile-v_hidden"
      data-widget="spacer"
      data-preset="default"
      data-spacing="laaa"
      data-visible-on="+desktop"
    >
      <div
        className="moto-widget-spacer-block"
        style={{ height: 0 }}
      />
    </div>
  </div>
</div>
</div>
</div>
</div>
</div>
</div>
</div> */}
                                    {/* <div
id="wid_1595842021_npa7vwnc1"
data-parent-id="wid_1595841006_s9ff8o2hk"
className="moto-widget moto-widget-content_slider__item moto-widget-row moto-widget_with-content-editor  row-fixed moto-disabling_row-fixed moto-entertainment__playing_disabled moto-before-in-viewport_content-invisible"
style={{
backgroundPosition: "top",
backgroundImage:
"url(mt-demo/109600/109604/mt-content/uploads/2020/07/mt-2068_home_slider03.jpg)",
backgroundSize: "cover",
backgroundRepeat: "no-repeat"
}}
data-widget="content_slider.item"
data-preset=""
>
<div className="moto-widget__content-wrapper container-fluid">
<div className="moto-widget__content row">
<div
id="wid_1595842021_npa7vwnc1__content"
className="moto-cell col-sm-12 moto-entertainment__animation_disabled moto-entertainment__animation_ignore-visibility"
>
<div
data-widget-id="wid_1595842021_x52c8pbjk"
className="moto-widget moto-widget-row moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto  "
data-grid-type="sm"
data-widget="row"
data-visible-on="+desktop,tablet,mobile-h,mobile-v"
data-spacing="lala"
style={{ backgroundPosition: "left top" }}
>
<div className="container-fluid">
<div className="row" data-container="container">
  <div
    data-widget-id="wid_1595842021_lx5dkmsgw"
    className="moto-widget moto-widget-row__column moto-cell  col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
    style={{ backgroundPosition: "left top" }}
    data-widget="row.column"
    data-container="container"
    data-spacing="aaaa"
  >
    <div
      data-widget-id="wid_1595842021_n6twdsc71"
      className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto moto-visible-on_tablet_hidden moto-visible-on_mobile-h_hidden moto-visible-on_mobile-v_hidden"
      data-widget="spacer"
      data-preset="default"
      data-spacing="laaa"
      data-visible-on="+desktop"
    >
      <div
        className="moto-widget-spacer-block"
        style={{ height: 0 }}
      />
    </div>
    <div
      data-widget-id="wid_1595842021_kj3z4k0vg"
      className="moto-widget moto-widget-text moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto  "
      data-widget="text"
      data-preset="default"
      data-spacing="aama"
      data-visible-on="+desktop,tablet,mobile-h,mobile-v"
      data-animation=""
    >
      <div className="moto-widget-text-content moto-widget-text-editable">
        <p className="moto-text_system_5">
          <span className="moto-color_custom3">
            HAVE ACCESS TO
          </span>
        </p>
        <p className="moto-text_system_3">A HEALTH</p>
        <p className="moto-text_system_3">
          PROFESSIONAL
        </p>
        <p className="moto-text_system_4">
          <span className="moto-color_custom3">
            AT ANY TIME
          </span>
        </p>
      </div>
    </div>
    <div
      data-widget-id="wid_1595842021_ju5ys6p8d"
      className="moto-widget moto-widget-button moto-preset-default moto-preset-provider-default moto-align-left moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
      data-widget="button"
    >
      <a
        href="#"
        data-action="popup"
        data-popup-id={8}
        className="moto-widget-button-link moto-size-small moto-link"
      >
        <span className="fa moto-widget-theme-icon" />
        <span className="moto-widget-button-divider" />
        <span className="moto-widget-button-label">
          Make an appointment&nbsp;
        </span>
      </a>
    </div>
    <div
      data-widget-id="wid_1595842021_8fugufps4"
      className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto moto-visible-on_tablet_hidden moto-visible-on_mobile-h_hidden moto-visible-on_mobile-v_hidden"
      data-widget="spacer"
      data-preset="default"
      data-spacing="laaa"
      data-visible-on="+desktop"
    >
      <div
        className="moto-widget-spacer-block"
        style={{ height: 0 }}
      />
    </div>
  </div>
</div>
</div>
</div>
</div>
</div>
</div>
</div> */}
                                </div>
                            </div>
                            <div
                                className="moto-widget moto-widget-row row-fixed moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto"
                                data-grid-type="sm"
                                data-widget="row"
                                data-visible-on="-"
                                data-spacing="lala"
                                style={{}}
                                data-bg-position="left top"
                                data-draggable-disabled=""
                            >
                                <div className='col-lg-12'>
                                    <div className='container'>
                                        <div class="card" style={{ padding: '20PX', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                            <div class="card-body">
                                                {/* <h1 style={{fontSize:'35px'}}>
                                        BECOMING A PARENT THROUGH SURROGACY STARTS HERE
                                        </h1> */}
                                                <br />

                                                <br />
                                                <p style={{ fontSize: '17PX', marginTop: '10px', fontWeight: 100, wordSpacing: '2PX' }}>

                                                    Embarking on the extraordinary journey to parenthood through surrogacy is a testament to the boundless
                                                    love and unwavering hope that defines your path. It’s a story of dreams taking flight, woven with threads
                                                    of courage, trust, and the remarkable strength of the human spirit. From the moment you set your sights
                                                    on this remarkable path, you become pioneers of a unique narrative, one that champions the beauty of
                                                    collaboration and the incredible selflessness of your chosen surrogate.
                                                    <br />  <br />

                                                </p>


                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="">
                                <div className="row" data-container="container">
                                    <div
                                        className="moto-widget moto-widget-row__column moto-cell col-sm-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                        style={{
                                            backgroundImage:
                                                "url(../mt-demo/109600/109604/mt-content/uploads/2020/07/5.jpg)",
                                            backgroundPosition: "top",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover"
                                        }}
                                        data-widget="row.column"
                                        data-container="container"
                                        data-spacing="aaaa"
                                        data-bg-position="top"
                                    // data-bg-image="2020/07/mt-2068_about_img01.jpg/index.html"
                                    >
                                        <div
                                            data-widget-id="wid_1595850301_fy666dpim"
                                            className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto "
                                            data-widget="spacer"
                                            data-preset="default"
                                            data-spacing="lala"
                                            data-visible-on="+desktop,tablet,mobile-h,mobile-v"
                                        >
                                            <div
                                                className="moto-widget-spacer-block"
                                                style={{ height: 200 }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="moto-widget moto-widget-row__column moto-cell col-sm-6 moto-bg-color1_3 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                        style={{}}
                                        data-widget="row.column"
                                        data-container="container"
                                        data-spacing="aaaa"
                                        data-bg-position="left top"
                                    >
                                        <div
                                            data-widget-id="wid_1595850266_7cfmprvop"
                                            className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto moto-visible-on_tablet_hidden moto-visible-on_mobile-h_hidden moto-visible-on_mobile-v_hidden"
                                            data-widget="spacer"
                                            data-preset="default"
                                            data-spacing="laaa"
                                            data-visible-on="+desktop"
                                        >
                                            <div
                                                className="moto-widget-spacer-block"
                                                style={{ height: 0 }}
                                            />
                                        </div>
                                        <div
                                            className="moto-widget moto-widget-row row-fixed moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto"
                                            data-grid-type="md"
                                            data-widget="row"
                                            data-visible-on="-"
                                            data-spacing="lala"
                                            style={{}}
                                            data-bg-position="left top"
                                            data-draggable-disabled=""
                                        >
                                            <div className="container-fluid">
                                                <div className="row" data-container="container">
                                                    <div
                                                        className="moto-widget moto-widget-row__column moto-cell col-md-1 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                                        style={{}}
                                                        data-widget="row.column"
                                                        data-container="container"
                                                        data-spacing="aaaa"
                                                        data-bg-position="left top"
                                                    ></div>
                                                    <div
                                                        className="moto-widget moto-widget-row__column moto-cell col-md-7 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                                        style={{}}
                                                        data-widget="row.column"
                                                        data-container="container"
                                                        data-spacing="aaaa"
                                                        data-bg-position="left top"
                                                    >
                                                        <div
                                                            className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                                            data-widget="text"
                                                            data-preset="default"
                                                            data-spacing="aaaa"
                                                            data-visible-on="-"
                                                            data-animation=""
                                                            data-draggable-disabled=""
                                                        >
                                                            <div
                                                                className="moto-widget-text-content moto-widget-text-editable"
                                                                aria-multiline="true"
                                                            >
                                                                <h1 className="moto-text_system_7">
                                                                    THE JOURNEY TO PARENTHOOD WITH PSC
                                                                </h1>

                                                                <p className="moto-text_system_12">
                                                                    <br />
                                                                </p>
                                                                <p className="moto-text_system_12">
                                                                    <br />
                                                                </p>
                                                                <p className="moto-text_system_12" style={{ fontSize: '17px', wordSpacing: '2px', }}>
                                                                    After researching various agencies, you chose to work with Possible surrogacy center (PSC),
                                                                    filled out the form and recently learned that PSC has chosen to work with you as well. Now what?
                                                                    This page details what happens after you’ve been accepted into our incredible surrogacy program.
                                                                    <br /><br />



                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="moto-widget moto-widget-row__column moto-cell col-md-4 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                                                        style={{}}
                                                        data-widget="row.column"
                                                        data-container="container"
                                                        data-spacing="aaaa"
                                                        data-bg-position="left top"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            data-widget-id="wid_1595850285_6k6bymyhz"
                                            className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto moto-visible-on_tablet_hidden moto-visible-on_mobile-h_hidden moto-visible-on_mobile-v_hidden"
                                            data-widget="spacer"
                                            data-preset="default"
                                            data-spacing="laaa"
                                            data-visible-on="+desktop"
                                        >
                                            <div
                                                className="moto-widget-spacer-block"
                                                style={{ height: 0 }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div
className="moto-widget moto-widget-row row-fixed moto-bg-color1_3 moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto"
data-grid-type="sm"
data-widget="row"
data-visible-on="-"
data-spacing="lala"
style={{}}
data-bg-position="left top"
>
<div className="container-fluid">
<div className="row" data-container="container">
<div
className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
style={{}}
data-widget="row.column"
data-container="container"
data-spacing="aaaa"
data-bg-position="left top"
>
<div
className="moto-widget moto-widget-row moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
data-grid-type="sm"
data-widget="row"
data-visible-on="-"
data-spacing="aaaa"
style={{}}
data-bg-position="left top"
>
<div className="container-fluid">
<div className="row" data-container="container">
<div
className="moto-widget moto-widget-row__column moto-cell col-sm-4 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
style={{}}
data-widget="row.column"
data-container="container"
data-spacing="aaaa"
data-bg-position="left top"
>
<div
className="moto-widget moto-widget-row row-gutter-0 moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto"
data-grid-type="xs"
data-widget="row"
data-visible-on="-"
data-spacing="mama"
style={{}}
data-bg-position="left top"
>
<div className="container-fluid">
  <div className="row" data-container="container">
    <div
      className="moto-widget moto-widget-row__column moto-cell col-xs-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
      style={{}}
      data-widget="row.column"
      data-container="container"
      data-spacing="aaaa"
      data-bg-position="left top"
    >
      <div
        id="wid_1595938464_vzx4oy3bc"
        className="moto-widget moto-widget-counter moto-preset-default moto-align-right moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
        data-widget="counter"
        data-moto-counter-options='{"countFrom":0,"countTo":58,"duration":3}'
      >
        <div className="moto-widget-counter__wrapper">
          <span className="moto-widget-counter__value moto-text_206">
            58
          </span>
        </div>
      </div>
    </div>
    <div
      className="moto-widget moto-widget-row__column moto-cell col-xs-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
      style={{}}
      data-widget="row.column"
      data-container="container"
      data-spacing="aaaa"
      data-bg-position="left top"
    >
      <div
        className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
        data-widget="text"
        data-preset="default"
        data-spacing="aaaa"
        data-visible-on="-"
        data-animation=""
      >
        <div className="moto-widget-text-content moto-widget-text-editable">
          <p className="moto-text_206">%</p>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<div
className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto"
data-widget="text"
data-preset="default"
data-spacing="aama"
data-visible-on="-"
data-animation=""
>
<div className="moto-widget-text-content moto-widget-text-editable">
  <p
    className="moto-text_system_12"
    style={{ textAlign: "center" }}
  >
    That's how much lower our prices are, compared to
    market's average
  </p>
</div>
</div>
</div>
<div
className="moto-widget moto-widget-row__column moto-cell col-sm-4 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
style={{}}
data-widget="row.column"
data-container="container"
data-spacing="aaaa"
data-bg-position="left top"
>
<div
className="moto-widget moto-widget-row row-gutter-0 moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto"
data-grid-type="xs"
data-widget="row"
data-visible-on="-"
data-spacing="mama"
style={{}}
data-bg-position="left top"
>
<div className="container-fluid">
  <div className="row" data-container="container">
    <div
      className="moto-widget moto-widget-row__column moto-cell col-xs-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
      style={{}}
      data-widget="row.column"
      data-container="container"
      data-spacing="aaaa"
      data-bg-position="left top"
    >
      <div
        id="wid_1595938528_y9wc8iqgz"
        className="moto-widget moto-widget-counter moto-preset-default moto-align-right moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
        data-widget="counter"
        data-moto-counter-options='{"countFrom":0,"countTo":95,"duration":3}'
      >
        <div className="moto-widget-counter__wrapper">
          <span className="moto-widget-counter__value moto-text_206">
            95
          </span>
        </div>
      </div>
    </div>
    <div
      className="moto-widget moto-widget-row__column moto-cell col-xs-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
      style={{}}
      data-widget="row.column"
      data-container="container"
      data-spacing="aaaa"
      data-bg-position="left top"
    >
      <div
        className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
        data-widget="text"
        data-preset="default"
        data-spacing="aaaa"
        data-visible-on="-"
        data-animation=""
      >
        <div className="moto-widget-text-content moto-widget-text-editable">
          <p className="moto-text_206">%</p>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<div
className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto"
data-widget="text"
data-preset="default"
data-spacing="aama"
data-visible-on="-"
data-animation=""
>
<div className="moto-widget-text-content moto-widget-text-editable">
  <p
    className="moto-text_system_12"
    style={{ textAlign: "center" }}
  >
    Percentage of patients who were referred in by their
    friends
  </p>
</div>
</div>
</div>
<div
className="moto-widget moto-widget-row__column moto-cell col-sm-4 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
style={{}}
data-widget="row.column"
data-container="container"
data-spacing="aaaa"
data-bg-position="left top"
>
<div
className="moto-widget moto-widget-row row-gutter-0 moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto"
data-grid-type="xs"
data-widget="row"
data-visible-on="-"
data-spacing="mama"
style={{}}
data-bg-position="left top"
>
<div className="container-fluid">
  <div className="row" data-container="container">
    <div
      className="moto-widget moto-widget-row__column moto-cell col-xs-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
      style={{}}
      data-widget="row.column"
      data-container="container"
      data-spacing="aaaa"
      data-bg-position="left top"
    >
      <div
        id="wid_1595938540_s1zclpse5"
        className="moto-widget moto-widget-counter moto-preset-default moto-align-right moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
        data-widget="counter"
        data-moto-counter-options='{"countFrom":0,"countTo":100,"duration":3}'
      >
        <div className="moto-widget-counter__wrapper">
          <span className="moto-widget-counter__value moto-text_206">
            100
          </span>
        </div>
      </div>
    </div>
    <div
      className="moto-widget moto-widget-row__column moto-cell col-xs-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
      style={{}}
      data-widget="row.column"
      data-container="container"
      data-spacing="aaaa"
      data-bg-position="left top"
    >
      <div
        className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
        data-widget="text"
        data-preset="default"
        data-spacing="aaaa"
        data-visible-on="-"
        data-animation=""
      >
        <div className="moto-widget-text-content moto-widget-text-editable">
          <p className="moto-text_206">%</p>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<div
className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto"
data-widget="text"
data-preset="default"
data-spacing="aama"
data-visible-on="-"
data-animation=""
data-draggable-disabled=""
>
<div
  className="moto-widget-text-content moto-widget-text-editable"
  aria-multiline="true"
>
  <p
    className="moto-text_system_12"
    style={{ textAlign: "center" }}
  >
    Pregnant women and their partners helped &amp;
    guided so far
  </p>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div> */}
                        <div className='col-lg-12' style={{ marginTop: '20px', marginBottom: '30px' }}>
                            <div className='container'>
                                <div class="card" style={{ padding: '20PX', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                    <div class="card-body">

                                        <br />

                                        <br />

                                        <b style={{ fontSize: '20px' }}>1. PRESENTATION OF THE AGENCY</b>
                                        <p style={{ fontSize: '20PX', marginTop: '10px', fontWeight: 100, wordSpacing: '2PX' }}>

                                            After receiving a welcome email from PSC with some informative documents attached, your
                                            first step is a consultation with one of our agency staff. During our presentation, we discuss
                                            the steps for surrogacy, our services, recommendations for attorneys or clinics if needed, and our
                                            fees, before answering any questions you may have. Based on the circumstances, this presentation takes
                                            place either in person, through Skype or over the phone — but always at your convenience.
                                            <br />  <br />
                                            <b style={{ fontSize: '20px' }}> 2. MATCHING WITH YOUR SURROGATE</b>
                                            <br />  <br />

                                            After deciding that you are ready to proceed, the second step is to select the surrogate with whom you feel most comfortable.
                                            We send you information about a few choices that we feel most closely match your preferences. If you do not find one you like,
                                            we will either send additional profiles or wait until we have a surrogate that best matches your desires.

                                            If you like a surrogate’s profile, please let us know within 24 hours. Once we send a profile to you,
                                            we “reserve” that surrogate for you for 24 hours. If we do not hear from you, we will assume you are not interested,
                                            and she will be made available to other intendant parents. If you are interested in a surrogate’s profile, we contact
                                            the surrogate and share your profile with her. If she is interested in your profile, we set up a Skype or in-person meeting so
                                            you all get to know one another.
                                            <br />  <br />
                                            <b style={{ fontSize: '20px' }}> 3. MEETING THE SURROGATE</b>
                                            <br />  <br />

                                            The third step is to meet the surrogate, either in person or through Skype. Both
                                            parties ask questions to get an idea of how each person feels about the process and
                                            to get to know one another better. Once you have met and decided that you want to work
                                            with the surrogate, you send us an email confirming that you want to match with her,
                                            and we will ask her to do the same. If both parties agree, we consider you matched.

                                            <br />  <br />
                                            <b style={{ fontSize: '20px' }}> 4. ESTABLISH CLIENT OR ESCROW ACCOUNT TO HOLD YOUR FUNDS</b>
                                            <br />  <br />

                                            After you are matched with a surrogate, you must establish a client account through your
                                            attorney or an escrow account in the United States. We will suggest an escrow company that
                                            we work with frequently or you can speak with your attorney regarding the holding of your funds.
                                            This escrow account/client account covers the Possible surrogacy center fee, surrogacy compensations
                                            and any other expenses.

                                            Establishing an escrow/client account in the U.S.
                                            is more convenient and less costly than paying expenses by transferring and
                                            converting currency each time. A priority of the Possible surrogacy center is to simplify the process for
                                            both parties and make the experience as pleasant as possible.

                                            <br />  <br />
                                            <b style={{ fontSize: '20px' }}>5. THE ROLE OF Possible surrogacy center</b>
                                            <br />  <br />

                                            PSC notifies all the professional parties involved that your journey is ready to begin.
                                            We send a referral to the criminal background agency to do a background check on your surrogate and
                                            her spouse/partner (if applicable). We send a referral to our psychologist who will screen your surrogate
                                            and her spouse/partner (if applicable). We notify the attorney you have chosen to represent you, and we
                                            notify the matching surrogate’s attorney.
                                            <br />  <br />
                                            <b style={{ fontSize: '20px' }}>6. CRIMINAL BACKGROUND AND PSYCHOLOGICAL EXAMINATION OF THE SURROGATE</b>
                                            <br />  <br />

                                            We submit the information provided by the surrogate for a criminal background of her and her
                                            spouse/partner (if applicable) to ensure that she does not have a criminal record. Following
                                            that, the surrogate undergoes a mental health screening to confirm that she is mentally
                                            stable to become a surrogate.

                                            The intendant parents are required to undergo a criminal background and mental health
                                            consultation as well. This step must be completed prior to your surrogate’s medical clearance,
                                            and the results submitted to us via email
                                            <br />  <br />
                                            <b style={{ fontSize: '20px' }}>7. MEDICAL EXAMINATION OF THE SURROGATE</b>
                                            <br />  <br />
                                            We submit your surrogate’s mental health screening result to the in vitro fertilization (IVF)
                                            clinic as soon as we receive it so her medical screening appointment can be scheduled. The
                                            IVF clinic contacts your surrogate and schedules her medical screening appointment based on
                                            where she is in her menstrual cycle.
                                            <br />  <br />
                                            <b style={{ fontSize: '20px' }}>8. PURCHASING HEALTH INSURANCE FOR YOUR BABY/BABIES</b>
                                            <br />  <br />
                                            Your surrogate’s health insurance covers her health care only throughout the surrogacy.
                                             It does not cover the baby (or babies) after birth. You have two options: (1) you can pay
                                              the hospital directly for the baby’s medical costs, or (2) you can purchase a separate health 
                                              insurance policy for the baby/babies.

                                            Since insurance is not our area of expertise, we highly suggest that you contact our 
                                            insurance specialist for more information. Your attorney will also give you information 
                                            regarding insurance for your baby/babies and can help you negotiate prices with the hospital.
                                            <br />  <br />
                                            <b style={{ fontSize: '20px' }}>9. SIGNING OF THE CONTRACT BY SURROGATE AND intendant PARENT(S)</b>
                                            <br />  <br />
                                            After your surrogate is medically cleared by your IVF doctor, we notify your 
                                            attorney that you are ready to review the legal contract that he/she has prepared for you 
                                            and your surrogate. Both parties and their respective lawyers review the contract before
                                             signing it. Once the contract is agreed upon by both parties, you must have the final contract
                                             notarized.
                                             <br />  <br />
                                            <b style={{ fontSize: '20px' }}>10. DEPOSIT TO THE TRUST ACCOUNT</b>
                                            <br />  <br />
                                            Within 7 days after signing the surrogacy contract, you are required to
                                             deposit $65,000 USD (for a first time surrogate) into your trust/escrow account to 
                                             cover your surrogate’s compensation. Please note that the exact initial deposit amount 
                                             may vary based upon the surrogate’s location and surrogacy experience. You may deposit an 
                                             extra amount of money to cover the cost of the egg donor cycle (if applicable), IVF doctor 
                                             specializing in infertility and/or any other expenses.


                                        </p>
                                        <div className='col-lg-12' style={{ marginTop: '20px' }}>
                                            <Link to="/become-parent-form">
                                            <button className='btn btn-info'
                                                style={{
                                                    width: '150px',
                                                    height: '46px',
                                                    fontSize: '12px',
                                                    border: 'none',
                                                    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',


                                                }}

                                            >Apply Now</button>
                                            </Link>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <div
    className="moto-widget moto-widget-row row-fixed moto-bg-color1_3 moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto"
    data-grid-type="sm"
    data-widget="row"
    data-visible-on="-"
    data-spacing="lala"
    style={{}}
    data-bg-position="left top"
>
    <div className="container-fluid">
        <div className="row" data-container="container">
            <div
                className="moto-widget moto-widget-row__column moto-cell col-sm-1 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                style={{}}
                data-widget="row.column"
                data-container="container"
                data-spacing="aaaa"
                data-bg-position="left top"
            ></div>
            <div
                className="moto-widget moto-widget-row__column moto-cell col-sm-10 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                style={{}}
                data-widget="row.column"
                data-container="container"
                data-spacing="aaaa"
                data-bg-position="left top"
            >
                <div
                    data-widget-id="wid_1595852193_dzprmkpuf"
                    className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto moto-visible-on_tablet_hidden moto-visible-on_mobile-h_hidden moto-visible-on_mobile-v_hidden"
                    data-widget="spacer"
                    data-preset="default"
                    data-spacing="lama"
                    data-visible-on="+desktop"
                >
                    <div
                        className="moto-widget-spacer-block"
                        style={{ height: 10 }}
                    />
                </div>
                <div
                    className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-medium moto-spacing-bottom-medium moto-spacing-left-medium"
                    data-widget="text"
                    data-preset="default"
                    data-spacing="ammm"
                    data-visible-on="-"
                    data-animation=""
                    data-draggable-disabled=""
                >
                    <div
                        className="moto-widget-text-content moto-widget-text-editable"
                        aria-multiline="true"
                    >
                        <p
                            className="moto-text_system_13"
                            style={{ textAlign: "center" }}
                        >
                            <span className="moto-color5_5">
                                WE'RE HIRING NEW INTERNS AND QUALIFIED MEDICAL PEOPLE ALL
                                YEAR LONG. IF YOU JUST RECENTLY GRADUATED FROM A MEDICAL
                                SCHOOL OR WANT TO SWITCH YOUR CURRENT POSITION FOR A WORK
                                AT OUR CLINIC, GET IN TOUCH WITH US!
                            </span>
                        </p>
                    </div>
                </div>
                <div
                    data-widget-id="wid_1595852077_4dlxle5lo"
                    className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto moto-visible-on_tablet_hidden moto-visible-on_mobile-h_hidden moto-visible-on_mobile-v_hidden"
                    data-widget="spacer"
                    data-preset="default"
                    data-spacing="aaaa"
                    data-visible-on="+desktop"
                >
                    <div
                        className="moto-widget-spacer-block"
                        style={{ height: 35 }}
                    />
                </div>
                <div
                    data-widget-id="wid_1595851814_q8d9io2m7"
                    className="moto-widget moto-widget-button moto-preset-5 moto-preset-provider-default moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
                    data-widget="button"
                >
                    <a
                        href="../our-team/index.html"
                        data-action="page"
                        className="moto-widget-button-link moto-size-large moto-link"
                    >
                        <span className="fa moto-widget-theme-icon" />
                        <span className="moto-widget-button-divider" />
                        <span className="moto-widget-button-label">Careers</span>
                    </a>
                </div>
                <div
                    data-widget-id="wid_1595852205_fsdg3w23a"
                    className="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto moto-visible-on_tablet_hidden moto-visible-on_mobile-h_hidden moto-visible-on_mobile-v_hidden"
                    data-widget="spacer"
                    data-preset="default"
                    data-spacing="lama"
                    data-visible-on="+desktop"
                >
                    <div
                        className="moto-widget-spacer-block"
                        style={{ height: 10 }}
                    />
                </div>
            </div>
            <div
                className="moto-widget moto-widget-row__column moto-cell col-sm-1 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                style={{}}
                data-widget="row.column"
                data-container="container"
                data-spacing="aaaa"
                data-bg-position="left top"
            ></div>
        </div>
    </div>
</div> */}
                        {/* <div
    className="moto-widget moto-widget-row row-fixed moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
    data-grid-type="md"
    data-widget="row"
    data-visible-on="-"
    data-spacing="aaaa"
    style={{
        backgroundImage:
            "url(../mt-demo/109600/109604/mt-content/uploads/2020/07/mt-2068_about_bg01.jpg)",
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
    }}
    data-bg-position="top"
    data-bg-image="2020/07/mt-2068_about_bg01.jpg"
    data-draggable-disabled=""
>
    <div className="container-fluid">
        <div className="row" data-container="container">
            <div
                className="moto-widget moto-widget-row__column moto-cell col-md-5 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
                style={{}}
                data-widget="row.column"
                data-container="container"
                data-spacing="aaaa"
                data-bg-position="left top"
            ></div>
            <div
                className="moto-widget moto-widget-row__column moto-cell col-md-7 moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
                data-widget="row.column"
                data-container="container"
                data-spacing="lala"
                data-bg-position="left top"
            >
                <div
                    className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto"
                    data-widget="text"
                    data-preset="default"
                    data-spacing="sasa"
                    data-visible-on="-"
                    data-animation=""
                    data-draggable-disabled=""
                >
                    <div
                        className="moto-widget-text-content moto-widget-text-editable"
                        aria-multiline="true"
                    >
                        <h2 className="moto-text_system_7">
                            <span style={{ color: "#228cf7" }}>FREQUENTLY</span>
                            <span className="moto-color_custom5">
                                &nbsp;ASKED QUESTIONS
                            </span>
                        </h2>
                        <p className="moto-text_normal">
                            <br />
                        </p>
                        <p className="moto-text_system_7">
                            <br />
                        </p>
                        <h3 className="moto-text_system_13">
                            <span style={{ color: "#228cf7" }}>Q:</span>&nbsp;WHAT ARE
                            THE ADVANTAGES OF PURCHASING A WEBSITE TEMPLATE?
                        </h3>
                        <p className="moto-text_normal">
                            <br />
                        </p>
                        <p className="moto-text_normal">
                            The major advantage is price: You get a high quality design
                            for just $20-$70. You don’t have to hire a web designer or
                            web design studio. Second advantage is time frame: It
                            usually takes 5-15 days for a good designer to produce a web
                            page of such quality.
                        </p>
                        <p className="moto-text_normal">
                            <br />
                        </p>
                        <p className="moto-text_normal">
                            Do you provide any scripts with your templates or could you
                            do some custom programming?
                        </p>
                        <p className="moto-text_normal">
                            <br />
                        </p>
                        <p className="moto-text_normal">
                            Our templates do not include any additional scripts.
                            Newsletter subscriptions, search fields, forums, image
                            galleries (in HTML versions of Flash products) are inactive.
                            Basic scripts can be easily added at www.TemplateTuning.com
                            If you are not sure that the element you’re interested in is
                            active please contact our Support Chat for clarification.
                        </p>
                        <p className="moto-text_normal">
                            <br />
                        </p>
                        <p className="moto-text_normal">
                            <br />
                        </p>
                        <h3 className="moto-text_system_13">
                            <span style={{ color: "#228cf7" }}>Q:</span>&nbsp;WHAT DO I
                            RECEIVE WHEN I ORDER A TEMPLATE FROM TEMPLATE.COM?
                        </h3>
                        <p className="moto-text_normal">
                            <br />
                        </p>
                        <p className="moto-text_normal">
                            After you complete the payment via our secure form you will
                            receive the instructions for downloading the product. The
                            source files in the download package can vary based on the
                            type of the product you have purchased.
                        </p>
                        <p className="moto-text_normal">
                            If you need unzipping software to open the .zip archive,
                            Windows users may visit www.HotScripts.com or
                            www.WinZip.com, Mac users may visit www.StuffIt.com.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> */}
                    </section>
                </div>
                <Footer />
                {/* <footer
id="section-footer"
className="footer moto-section"
data-widget="section"
data-container="section"
data-moto-sticky="{mode:'smallHeight', direction:'bottom', mobile: 0}"
>
<div
className="moto-widget moto-widget-row row-fixed moto-justify-content_center moto-bg-color_custom3 moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto"
data-grid-type="sm"
data-widget="row"
data-visible-on="-"
data-spacing="mama"
style={{}}
data-bg-position="left top"
data-draggable-disabled=""
>
<div className="container-fluid">
<div className="row" data-container="container">
<div
className="moto-widget moto-widget-row__column moto-cell col-sm-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
style={{}}
data-widget="row.column"
data-container="container"
data-spacing="aaaa"
data-bg-position="left top"
>
<div
className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto"
data-widget="text"
data-preset="default"
data-spacing="sasa"
data-visible-on="-"
data-animation=""
data-draggable-disabled=""
>
<div
className="moto-widget-text-content moto-widget-text-editable"
aria-multiline="true"
>
<p className="moto-text_system_10">
2020&nbsp;© ItsaGirl. All rights reserved. &nbsp;| &nbsp;
<a
target="_self"
href="../privacy-policy/index.html"
data-action="page"
data-id={18}
className="moto-link"
>
Privacy Policy
</a>
</p>
</div>
</div>
</div>
<div
className="moto-widget moto-widget-row__column moto-cell col-sm-6 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto"
style={{}}
data-widget="row.column"
data-container="container"
data-spacing="aaaa"
data-bg-position="left top"
>
<div
id="wid_1595840854_kjd4m8lxl"
data-widget-id="wid_1595840854_kjd4m8lxl"
className="moto-widget moto-widget-social-links-extended moto-preset-2 moto-align-right moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  "
data-widget="social_links_extended"
data-preset={2}
>
<ul className="moto-widget-social-links-extended__list">
<li className="moto-widget-social-links-extended__item moto-widget-social-links-extended__item-1">
<a
href="#"
className="moto-widget-social-links-extended__link"
target="_self"
>
<span className="moto-widget-social-links-extended__icon fa">

</span>
</a>
</li>
<li className="moto-widget-social-links-extended__item moto-widget-social-links-extended__item-2">
<a
href="#"
className="moto-widget-social-links-extended__link"
target="_self"
>
<span className="moto-widget-social-links-extended__icon fa">

</span>
</a>
</li>
<li className="moto-widget-social-links-extended__item moto-widget-social-links-extended__item-3">
<a
href="#"
className="moto-widget-social-links-extended__link"
target="_self"
>
<span className="moto-widget-social-links-extended__icon fa">

</span>
</a>
</li>
<li className="moto-widget-social-links-extended__item moto-widget-social-links-extended__item-4">
<a
href="#"
className="moto-widget-social-links-extended__link"
target="_self"
>
<span className="moto-widget-social-links-extended__icon fa">

</span>
</a>
</li>
</ul>
<style
type="text/css"
dangerouslySetInnerHTML={{
__html:
"\n                                                        "
}}
/>
</div>
</div>
</div>
</div>
</div>
</footer> */}
                {/* <div data-moto-back-to-top-button="" className="moto-back-to-top-button">
<a ng-click="toTop($event)" className="moto-back-to-top-button-link">
<span className="moto-back-to-top-button-icon fa" />
</a>
</div>
<div
data-moto-widget-callback=""
className="moto-widget-callback moto-widget-callback_closed moto-preset-default"
>
<div className="moto-widget-callback__wrapper">
<div className="moto-widget-callback__open-button moto-widget-callback__thumbnail-wrapper moto-widget-callback__thumbnail-wrapper_icon">
    <div className="moto-widget-callback__overlay moto-widget-callback__overlay_open-button" />
    <div className="moto-widget-callback__thumbnail moto-widget-callback__thumbnail_icon fa fa-fw fa-phone" />
</div>
<div
    className="moto-widget-callback__body moto-widget-callback__body_more-details-enabled"
    style={{ display: "none" }}
>
    <div className="moto-widget-callback__agent moto-widget-callback__thumbnail-wrapper moto-widget-callback__thumbnail-wrapper_image">
        <div
            className="moto-widget-callback__thumbnail moto-widget-callback__thumbnail_image"
            style={{
                backgroundImage:
                    "url(../mt-demo/109600/109604/mt-content/uploads/2020/07/mt-2068_doctors_team02.jpg)"
            }}
        />
    </div>
    <div className="moto-widget-callback__description moto-widget-text">
        <p className="moto-text_system_8" style={{ textAlign: "center" }}>
            John Smith
        </p>
        <p className="moto-text_normal" style={{ textAlign: "center" }}>
            <span className="moto-color1_3">agent</span>
        </p>
        <p className="moto-text_normal" style={{ textAlign: "center" }}>
            &nbsp;
        </p>
        <p className="moto-text_220" style={{ textAlign: "center" }}>
            <a
                className="moto-link"
                data-action="call"
                href="tel:+112233445566"
            >
                <span className="fa"></span> +112233445566
            </a>
        </p>{" "}
    </div>
    <div className="moto-widget-callback__more-details-wrapper">
        <hr className="moto-widget-callback__more-details-divider" />
        <div className="moto-widget-callback__more-details">
            <div className="moto-widget-callback__more-details-item">
                <div className="moto-widget-callback__overlay moto-widget-callback__overlay_link" />
                <a
                    href="../../t.me/index.html#"
                    className="moto-widget-callback__link moto-widget-callback__link-telegram_chat"
                    title="Telegram"
                    target="_blank"
                />
            </div>
            <div className="moto-widget-callback__more-details-item">
                <div className="moto-widget-callback__overlay moto-widget-callback__overlay_link" />
                <a
                    href="../../wa.me/index.html#"
                    className="moto-widget-callback__link moto-widget-callback__link-whatsapp_chat"
                    title="WhatsApp"
                    target="_blank"
                />
            </div>
            <div className="moto-widget-callback__more-details-item">
                <div className="moto-widget-callback__overlay moto-widget-callback__overlay_link" />
                <a
                    href="viber://pa/info?uri=#"
                    className="moto-widget-callback__link moto-widget-callback__link-viber_public_account"
                    title="Viber"
                    target="_blank"
                />
            </div>
            <div className="moto-widget-callback__more-details-item">
                <div className="moto-widget-callback__overlay moto-widget-callback__overlay_link" />
                <a
                    href="skype:#?call"
                    className="moto-widget-callback__link moto-widget-callback__link-skype_call"
                    title="Skype (Call)"
                    target="_blank"
                />
            </div>
        </div>
    </div>
    <div className="moto-widget-callback__more-details-button-wrapper">
        <i className="moto-widget-callback__more-details-button fa fa-angle-down" />
    </div>
    <div className="moto-widget-callback__close-button fa fa-times" />
</div>
</div>
</div> */}
            </body>


        </>
    )
}

export default Parenthood
